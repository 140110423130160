import { HomeWork, HowToReg, LocalLibrary, Person, Quiz, School } from '@mui/icons-material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Divider, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ReactNode, useState } from 'react';
import { AccessLevel, PageName, UserInfo, allLevels, getPageNameFromString } from '../AppConstants';
import { NaviItem } from './NaviItem';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ArticleIcon from '@mui/icons-material/Article';

export interface NaviProps {
  open: boolean
  selectedPage: PageName
  userInfo: UserInfo
  onClose: () => void
  handlePageSelect?: (page: PageName, year: string, level: string) => void
}

export function Navigator(props: NaviProps) {
  const applicableLevels = allLevels.filter(level => props.userInfo.level.includes(level))
  const [selectedPage, setSelectedPage] = useState<PageName>(props.selectedPage)
  const [selectedYear, setSelectedYear] = useState<string>(props.userInfo.year[0])
  const [selectedLevel, setSelectedLevel] = useState<string>(props.userInfo.level[0])

  console.log("Navigator: selectedPage: " + selectedPage + ", selectedYear: " + selectedYear + ", selectedLevel: " + selectedLevel)

  const handleItemClick = (page: string) => {
    const pageName = getPageNameFromString(page)
    setSelectedPage(pageName)
    props.handlePageSelect && props.handlePageSelect(pageName, selectedYear, selectedLevel)
  }

  function handleYearChange(event: SelectChangeEvent<string>, child: ReactNode): void {
    console.log(event.target.value)
    setSelectedYear(event.target.value)
    props.handlePageSelect && props.handlePageSelect(selectedPage, event.target.value, selectedLevel)
  }

  function handleLevelChange(event: SelectChangeEvent<string>, child: ReactNode): void {
    console.log(event.target.value)
    setSelectedLevel(event.target.value)
    props.handlePageSelect && props.handlePageSelect(selectedPage, selectedYear, event.target.value)
  }

  const drawerContent = <List disablePadding>
    <ListItem sx={{ bgcolor: "primary.main", color: 'white' }}>
      <ListItemIcon>
        <School sx={{ color: 'white' }} />
      </ListItemIcon>
      <ListItemText>WestKaty TamilSchool</ListItemText>
    </ListItem>

    <Box>
      <ListItem>
        <ListItemText>Teachers</ListItemText>
      </ListItem>
      {props.userInfo.year.length > 1 &&
        <Box display='flex'>
          <FormControl fullWidth sx={{ margin: 2 }}>
            <InputLabel id="ptc-year-select-label">SchoolYear</InputLabel>
            <Select
              labelId="ptc-year-select-label"
              id="ptc-year-select"
              value={selectedYear}
              label="SchoolYear"
              onChange={handleYearChange}
            >
              {props.userInfo.year.map((year, index) => <MenuItem key={index} value={year}>{year}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>}
      {applicableLevels.length > 1 &&
        <Box display='flex'>
          <FormControl fullWidth sx={{ margin: 2 }}>
            <InputLabel id="ptc-level-select-label">Level</InputLabel>
            <Select
              labelId="ptc-level-select-label"
              id="ptc-level-select"
              value={selectedLevel}
              label="Level"
              onChange={handleLevelChange}
            >
              {applicableLevels.map((level, index) => <MenuItem key={index} value={level}>{level}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>}
      {(props.userInfo.studentInfoAccess === AccessLevel.ReadOnly || props.userInfo.libraryAccess === AccessLevel.ReadWrite) &&
        <NaviItem name={PageName.Students} selected={selectedPage} icon={<Person />} onClick={handleItemClick} />}
      <NaviItem name={PageName.Attendance} selected={selectedPage} icon={<HowToReg />} onClick={handleItemClick} />
      <NaviItem name={PageName.Homework} selected={selectedPage} icon={<HomeWork />} onClick={handleItemClick} />
      <NaviItem name={PageName.Exam} selected={selectedPage} icon={<Quiz />} onClick={handleItemClick} />
    </Box>
    {props.userInfo.type === 'PTC' &&
      <Box>
        <ListItem>
          <ListItemText>PTC</ListItemText>
        </ListItem>
        <NaviItem name={PageName.TeacherAttendance} selected={selectedPage} icon={<HowToReg />} onClick={handleItemClick} />
        {(props.userInfo.libraryAccess === AccessLevel.ReadOnly || props.userInfo.libraryAccess === AccessLevel.ReadWrite) &&
          <NaviItem name={PageName.Library} selected={selectedPage} icon={<LocalLibrary />} onClick={handleItemClick} />}
        {(props.userInfo.userAccess === AccessLevel.ReadOnly || props.userInfo.userAccess === AccessLevel.ReadWrite) &&
          <NaviItem name={PageName.Users} selected={selectedPage} icon={<PeopleAltIcon />} onClick={handleItemClick} />}
        <NaviItem name={PageName.ExamSetup} selected={selectedPage} icon={<AssignmentTurnedInIcon />} onClick={handleItemClick} />
        <Divider />
        <NaviItem name={PageName.AllStudents} selected={selectedPage} icon={<PeopleAltIcon />} onClick={handleItemClick} />
        <Divider />
        <NaviItem name={PageName.ExamSummary} selected={selectedPage} icon={<ArticleIcon />} onClick={handleItemClick} />
        <NaviItem name={PageName.Log} selected={selectedPage} icon={<ArticleIcon />} onClick={handleItemClick} />
      </Box>}
  </List>;

  return (
    <Drawer open={props.open} onClose={props.onClose}>
      {drawerContent}
    </Drawer>
  );
}